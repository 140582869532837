import { ReportModuleListRequest } from '@app/lib/api/report/api.report.models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DropdownItem } from './module-list.state';

export const ModuleListActions = createActionGroup({
  source: 'ModuleList',
  events: {
    'On Get Module List By Role': props<{
      data: ReportModuleListRequest;
    }>(),

    'On Get Module Review List By Role': props<{
      data: ReportModuleListRequest;
    }>()
  }
});

export const ModuleListApiActions = createActionGroup({
  source: 'ModuleList Api',
  events: {
    'On Load Module List Success': props<{ data: DropdownItem[] }>(),
    'On Load Module Review List Success': props<{ data: DropdownItem[] }>(),
    'On Load Module List Failed': props<{ message: string }>(),

    'On Toggle DropDown item': props<{ index: number }>(),

    'On Load List By Value Module Success': props<{ module_item: any; index: number }>(),

    'On Get Module List By Role Success': props<{ data: DropdownItem[] }>(),
    'On Reset Module List': emptyProps()
  }
});
