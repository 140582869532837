import { ModerationAreas, ModeratorRole } from '@app/lib/api/user/api.user.model';
import { UserInfo } from '@app/shared/models/user';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UserInfoActions = createActionGroup({
  source: 'UserInfo',
  events: {
    'On Get User Info': props<{ userId: string }>(),
    'On Update User Name': props<{ first_name: string; last_name: string }>(),
    'On Update Avatar User Info': props<{ avatar_thumbnail_url: string }>(),
    'On Update Frame User Info': props<{ frame_url: string }>(),
    'On Get Admin Roles': emptyProps(),
    'On Get Admin Areas': props<{ textSearch: string }>(),
    'On Reset User State': emptyProps()
  }
});

export const UserInfoApiActions = createActionGroup({
  source: 'UserInfo Api',
  events: {
    'On Get User Info Success': props<{ userInfo: UserInfo }>(),
    'On Get User Info Fail': props<{ message: string }>(),

    'On Get Admin Roles Success': props<{ adminRoles: ModeratorRole[] }>(),
    'On Get Admin Roles Fail': props<{ message: string }>(),

    'On Get Admin Areas Success': props<{ adminAreas: ModerationAreas[] }>(),
    'On Select Admin Areas': props<{ area: ModerationAreas }>(),
    'On Get Admin Areas Fail': props<{ message: string }>(),
    'On Set Token To Load Iframe': props<{ tokenToLoadIframe: string }>()
  }
});
